import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7c010432 = () => interopDefault(import('../pages/authorization/index.vue' /* webpackChunkName: "pages/authorization/index" */))
const _642e7ac2 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _f1bff256 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "" */))
const _7dae2f7e = () => interopDefault(import('../pages/partner/index.vue' /* webpackChunkName: "pages/partner/index" */))
const _51d4afe2 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _01bbc8f6 = () => interopDefault(import('../pages/registration/index.vue' /* webpackChunkName: "pages/registration/index" */))
const _ec323954 = () => interopDefault(import('../pages/request-password/index.vue' /* webpackChunkName: "pages/request-password/index" */))
const _072b98a5 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _105da296 = () => interopDefault(import('../pages/success-payment/index.vue' /* webpackChunkName: "pages/success-payment/index" */))
const _eeb8e954 = () => interopDefault(import('../pages/partner/activations-history/index.vue' /* webpackChunkName: "pages/partner/activations-history/index" */))
const _1119f12f = () => interopDefault(import('../pages/partner/price-regulator/index.vue' /* webpackChunkName: "pages/partner/price-regulator/index" */))
const _44c671ea = () => interopDefault(import('../pages/partner/sim-card/index.vue' /* webpackChunkName: "pages/partner/sim-card/index" */))
const _4beba182 = () => interopDefault(import('../pages/partner/statistic/index.vue' /* webpackChunkName: "pages/partner/statistic/index" */))
const _143049da = () => interopDefault(import('../pages/partner/withdrawal-funds/index.vue' /* webpackChunkName: "pages/partner/withdrawal-funds/index" */))
const _c51c8fb2 = () => interopDefault(import('../pages/profile/history/index.vue' /* webpackChunkName: "pages/profile/history/index" */))
const _5bcee760 = () => interopDefault(import('../pages/profile/loyalty-program/index.vue' /* webpackChunkName: "pages/profile/loyalty-program/index" */))
const _3d17f73b = () => interopDefault(import('../pages/profile/pay/index.vue' /* webpackChunkName: "pages/profile/pay/index" */))
const _6f452ff7 = () => interopDefault(import('../pages/profile/referral-program/index.vue' /* webpackChunkName: "pages/profile/referral-program/index" */))
const _3ac40da7 = () => interopDefault(import('../pages/profile/sale-agreement/index.vue' /* webpackChunkName: "pages/profile/sale-agreement/index" */))
const _c32da5f4 = () => interopDefault(import('../pages/profile/settings/index.vue' /* webpackChunkName: "pages/profile/settings/index" */))
const _1274a006 = () => interopDefault(import('../pages/profile/statistics/index.vue' /* webpackChunkName: "pages/profile/statistics/index" */))
const _0e2af1a2 = () => interopDefault(import('../pages/profile/tariffs/index.vue' /* webpackChunkName: "pages/profile/tariffs/index" */))
const _34914412 = () => interopDefault(import('../pages/signup/confirm-email.vue' /* webpackChunkName: "pages/signup/confirm-email" */))
const _679bbd52 = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _c450bd78 = () => interopDefault(import('../pages/bot-link/_link.vue' /* webpackChunkName: "pages/bot-link/_link" */))
const _743042a3 = () => interopDefault(import('../pages/confirm/_token.vue' /* webpackChunkName: "pages/confirm/_token" */))
const _60ed723a = () => interopDefault(import('../pages/countries/_.vue' /* webpackChunkName: "pages/countries/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/authorization",
    component: _7c010432,
    pathToRegexpOptions: {"strict":true},
    name: "authorization___en"
  }, {
    path: "/blog",
    component: _642e7ac2,
    pathToRegexpOptions: {"strict":true},
    name: "blog___en"
  }, {
    path: "/cn",
    components: {
      default: _f1bff256
    },
    name: "index___cn"
  }, {
    path: "/partner",
    component: _7dae2f7e,
    pathToRegexpOptions: {"strict":true},
    name: "partner___en"
  }, {
    path: "/profile",
    component: _51d4afe2,
    pathToRegexpOptions: {"strict":true},
    name: "profile___en"
  }, {
    path: "/registration",
    component: _01bbc8f6,
    pathToRegexpOptions: {"strict":true},
    name: "registration___en"
  }, {
    path: "/request-password",
    component: _ec323954,
    pathToRegexpOptions: {"strict":true},
    name: "request-password___en"
  }, {
    path: "/ru",
    components: {
      default: _f1bff256
    },
    name: "index___ru"
  }, {
    path: "/search",
    component: _072b98a5,
    pathToRegexpOptions: {"strict":true},
    name: "search___en"
  }, {
    path: "/success-payment",
    component: _105da296,
    pathToRegexpOptions: {"strict":true},
    name: "success-payment___en"
  }, {
    path: "/tr",
    components: {
      default: _f1bff256
    },
    name: "index___tr"
  }, {
    path: "/cn/authorization",
    component: _7c010432,
    pathToRegexpOptions: {"strict":true},
    name: "authorization___cn"
  }, {
    path: "/cn/blog",
    component: _642e7ac2,
    pathToRegexpOptions: {"strict":true},
    name: "blog___cn"
  }, {
    path: "/cn/partner",
    component: _7dae2f7e,
    pathToRegexpOptions: {"strict":true},
    name: "partner___cn"
  }, {
    path: "/cn/profile",
    component: _51d4afe2,
    pathToRegexpOptions: {"strict":true},
    name: "profile___cn"
  }, {
    path: "/cn/registration",
    component: _01bbc8f6,
    pathToRegexpOptions: {"strict":true},
    name: "registration___cn"
  }, {
    path: "/cn/request-password",
    component: _ec323954,
    pathToRegexpOptions: {"strict":true},
    name: "request-password___cn"
  }, {
    path: "/cn/search",
    component: _072b98a5,
    pathToRegexpOptions: {"strict":true},
    name: "search___cn"
  }, {
    path: "/cn/success-payment",
    component: _105da296,
    pathToRegexpOptions: {"strict":true},
    name: "success-payment___cn"
  }, {
    path: "/partner/activations-history",
    component: _eeb8e954,
    pathToRegexpOptions: {"strict":true},
    name: "partner-activations-history___en"
  }, {
    path: "/partner/price-regulator",
    component: _1119f12f,
    pathToRegexpOptions: {"strict":true},
    name: "partner-price-regulator___en"
  }, {
    path: "/partner/sim-card",
    component: _44c671ea,
    pathToRegexpOptions: {"strict":true},
    name: "partner-sim-card___en"
  }, {
    path: "/partner/statistic",
    component: _4beba182,
    pathToRegexpOptions: {"strict":true},
    name: "partner-statistic___en"
  }, {
    path: "/partner/withdrawal-funds",
    component: _143049da,
    pathToRegexpOptions: {"strict":true},
    name: "partner-withdrawal-funds___en"
  }, {
    path: "/profile/history",
    component: _c51c8fb2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-history___en"
  }, {
    path: "/profile/loyalty-program",
    component: _5bcee760,
    pathToRegexpOptions: {"strict":true},
    name: "profile-loyalty-program___en"
  }, {
    path: "/profile/pay",
    component: _3d17f73b,
    pathToRegexpOptions: {"strict":true},
    name: "profile-pay___en"
  }, {
    path: "/profile/referral-program",
    component: _6f452ff7,
    pathToRegexpOptions: {"strict":true},
    name: "profile-referral-program___en"
  }, {
    path: "/profile/sale-agreement",
    component: _3ac40da7,
    pathToRegexpOptions: {"strict":true},
    name: "profile-sale-agreement___en"
  }, {
    path: "/profile/settings",
    component: _c32da5f4,
    pathToRegexpOptions: {"strict":true},
    name: "profile-settings___en"
  }, {
    path: "/profile/statistics",
    component: _1274a006,
    pathToRegexpOptions: {"strict":true},
    name: "profile-statistics___en"
  }, {
    path: "/profile/tariffs",
    component: _0e2af1a2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-tariffs___en"
  }, {
    path: "/ru/authorization",
    component: _7c010432,
    pathToRegexpOptions: {"strict":true},
    name: "authorization___ru"
  }, {
    path: "/ru/blog",
    component: _642e7ac2,
    pathToRegexpOptions: {"strict":true},
    name: "blog___ru"
  }, {
    path: "/ru/partner",
    component: _7dae2f7e,
    pathToRegexpOptions: {"strict":true},
    name: "partner___ru"
  }, {
    path: "/ru/profile",
    component: _51d4afe2,
    pathToRegexpOptions: {"strict":true},
    name: "profile___ru"
  }, {
    path: "/ru/registration",
    component: _01bbc8f6,
    pathToRegexpOptions: {"strict":true},
    name: "registration___ru"
  }, {
    path: "/ru/request-password",
    component: _ec323954,
    pathToRegexpOptions: {"strict":true},
    name: "request-password___ru"
  }, {
    path: "/ru/search",
    component: _072b98a5,
    pathToRegexpOptions: {"strict":true},
    name: "search___ru"
  }, {
    path: "/ru/success-payment",
    component: _105da296,
    pathToRegexpOptions: {"strict":true},
    name: "success-payment___ru"
  }, {
    path: "/signup/confirm-email",
    component: _34914412,
    pathToRegexpOptions: {"strict":true},
    name: "signup-confirm-email___en"
  }, {
    path: "/tr/authorization",
    component: _7c010432,
    pathToRegexpOptions: {"strict":true},
    name: "authorization___tr"
  }, {
    path: "/tr/blog",
    component: _642e7ac2,
    pathToRegexpOptions: {"strict":true},
    name: "blog___tr"
  }, {
    path: "/tr/partner",
    component: _7dae2f7e,
    pathToRegexpOptions: {"strict":true},
    name: "partner___tr"
  }, {
    path: "/tr/profile",
    component: _51d4afe2,
    pathToRegexpOptions: {"strict":true},
    name: "profile___tr"
  }, {
    path: "/tr/registration",
    component: _01bbc8f6,
    pathToRegexpOptions: {"strict":true},
    name: "registration___tr"
  }, {
    path: "/tr/request-password",
    component: _ec323954,
    pathToRegexpOptions: {"strict":true},
    name: "request-password___tr"
  }, {
    path: "/tr/search",
    component: _072b98a5,
    pathToRegexpOptions: {"strict":true},
    name: "search___tr"
  }, {
    path: "/tr/success-payment",
    component: _105da296,
    pathToRegexpOptions: {"strict":true},
    name: "success-payment___tr"
  }, {
    path: "/cn/partner/activations-history",
    component: _eeb8e954,
    pathToRegexpOptions: {"strict":true},
    name: "partner-activations-history___cn"
  }, {
    path: "/cn/partner/price-regulator",
    component: _1119f12f,
    pathToRegexpOptions: {"strict":true},
    name: "partner-price-regulator___cn"
  }, {
    path: "/cn/partner/sim-card",
    component: _44c671ea,
    pathToRegexpOptions: {"strict":true},
    name: "partner-sim-card___cn"
  }, {
    path: "/cn/partner/statistic",
    component: _4beba182,
    pathToRegexpOptions: {"strict":true},
    name: "partner-statistic___cn"
  }, {
    path: "/cn/partner/withdrawal-funds",
    component: _143049da,
    pathToRegexpOptions: {"strict":true},
    name: "partner-withdrawal-funds___cn"
  }, {
    path: "/cn/profile/history",
    component: _c51c8fb2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-history___cn"
  }, {
    path: "/cn/profile/loyalty-program",
    component: _5bcee760,
    pathToRegexpOptions: {"strict":true},
    name: "profile-loyalty-program___cn"
  }, {
    path: "/cn/profile/pay",
    component: _3d17f73b,
    pathToRegexpOptions: {"strict":true},
    name: "profile-pay___cn"
  }, {
    path: "/cn/profile/referral-program",
    component: _6f452ff7,
    pathToRegexpOptions: {"strict":true},
    name: "profile-referral-program___cn"
  }, {
    path: "/cn/profile/sale-agreement",
    component: _3ac40da7,
    pathToRegexpOptions: {"strict":true},
    name: "profile-sale-agreement___cn"
  }, {
    path: "/cn/profile/settings",
    component: _c32da5f4,
    pathToRegexpOptions: {"strict":true},
    name: "profile-settings___cn"
  }, {
    path: "/cn/profile/statistics",
    component: _1274a006,
    pathToRegexpOptions: {"strict":true},
    name: "profile-statistics___cn"
  }, {
    path: "/cn/profile/tariffs",
    component: _0e2af1a2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-tariffs___cn"
  }, {
    path: "/cn/signup/confirm-email",
    component: _34914412,
    pathToRegexpOptions: {"strict":true},
    name: "signup-confirm-email___cn"
  }, {
    path: "/ru/partner/activations-history",
    component: _eeb8e954,
    pathToRegexpOptions: {"strict":true},
    name: "partner-activations-history___ru"
  }, {
    path: "/ru/partner/price-regulator",
    component: _1119f12f,
    pathToRegexpOptions: {"strict":true},
    name: "partner-price-regulator___ru"
  }, {
    path: "/ru/partner/sim-card",
    component: _44c671ea,
    pathToRegexpOptions: {"strict":true},
    name: "partner-sim-card___ru"
  }, {
    path: "/ru/partner/statistic",
    component: _4beba182,
    pathToRegexpOptions: {"strict":true},
    name: "partner-statistic___ru"
  }, {
    path: "/ru/partner/withdrawal-funds",
    component: _143049da,
    pathToRegexpOptions: {"strict":true},
    name: "partner-withdrawal-funds___ru"
  }, {
    path: "/ru/profile/history",
    component: _c51c8fb2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-history___ru"
  }, {
    path: "/ru/profile/loyalty-program",
    component: _5bcee760,
    pathToRegexpOptions: {"strict":true},
    name: "profile-loyalty-program___ru"
  }, {
    path: "/ru/profile/pay",
    component: _3d17f73b,
    pathToRegexpOptions: {"strict":true},
    name: "profile-pay___ru"
  }, {
    path: "/ru/profile/referral-program",
    component: _6f452ff7,
    pathToRegexpOptions: {"strict":true},
    name: "profile-referral-program___ru"
  }, {
    path: "/ru/profile/sale-agreement",
    component: _3ac40da7,
    pathToRegexpOptions: {"strict":true},
    name: "profile-sale-agreement___ru"
  }, {
    path: "/ru/profile/settings",
    component: _c32da5f4,
    pathToRegexpOptions: {"strict":true},
    name: "profile-settings___ru"
  }, {
    path: "/ru/profile/statistics",
    component: _1274a006,
    pathToRegexpOptions: {"strict":true},
    name: "profile-statistics___ru"
  }, {
    path: "/ru/profile/tariffs",
    component: _0e2af1a2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-tariffs___ru"
  }, {
    path: "/ru/signup/confirm-email",
    component: _34914412,
    pathToRegexpOptions: {"strict":true},
    name: "signup-confirm-email___ru"
  }, {
    path: "/tr/partner/activations-history",
    component: _eeb8e954,
    pathToRegexpOptions: {"strict":true},
    name: "partner-activations-history___tr"
  }, {
    path: "/tr/partner/price-regulator",
    component: _1119f12f,
    pathToRegexpOptions: {"strict":true},
    name: "partner-price-regulator___tr"
  }, {
    path: "/tr/partner/sim-card",
    component: _44c671ea,
    pathToRegexpOptions: {"strict":true},
    name: "partner-sim-card___tr"
  }, {
    path: "/tr/partner/statistic",
    component: _4beba182,
    pathToRegexpOptions: {"strict":true},
    name: "partner-statistic___tr"
  }, {
    path: "/tr/partner/withdrawal-funds",
    component: _143049da,
    pathToRegexpOptions: {"strict":true},
    name: "partner-withdrawal-funds___tr"
  }, {
    path: "/tr/profile/history",
    component: _c51c8fb2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-history___tr"
  }, {
    path: "/tr/profile/loyalty-program",
    component: _5bcee760,
    pathToRegexpOptions: {"strict":true},
    name: "profile-loyalty-program___tr"
  }, {
    path: "/tr/profile/pay",
    component: _3d17f73b,
    pathToRegexpOptions: {"strict":true},
    name: "profile-pay___tr"
  }, {
    path: "/tr/profile/referral-program",
    component: _6f452ff7,
    pathToRegexpOptions: {"strict":true},
    name: "profile-referral-program___tr"
  }, {
    path: "/tr/profile/sale-agreement",
    component: _3ac40da7,
    pathToRegexpOptions: {"strict":true},
    name: "profile-sale-agreement___tr"
  }, {
    path: "/tr/profile/settings",
    component: _c32da5f4,
    pathToRegexpOptions: {"strict":true},
    name: "profile-settings___tr"
  }, {
    path: "/tr/profile/statistics",
    component: _1274a006,
    pathToRegexpOptions: {"strict":true},
    name: "profile-statistics___tr"
  }, {
    path: "/tr/profile/tariffs",
    component: _0e2af1a2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-tariffs___tr"
  }, {
    path: "/tr/signup/confirm-email",
    component: _34914412,
    pathToRegexpOptions: {"strict":true},
    name: "signup-confirm-email___tr"
  }, {
    path: "/cn/blog/:slug",
    component: _679bbd52,
    pathToRegexpOptions: {"strict":true},
    name: "blog-slug___cn"
  }, {
    path: "/cn/bot-link/:link?",
    component: _c450bd78,
    pathToRegexpOptions: {"strict":true},
    name: "bot-link-link___cn"
  }, {
    path: "/cn/confirm/:token?",
    component: _743042a3,
    pathToRegexpOptions: {"strict":true},
    name: "confirm-token___cn"
  }, {
    path: "/ru/blog/:slug",
    component: _679bbd52,
    pathToRegexpOptions: {"strict":true},
    name: "blog-slug___ru"
  }, {
    path: "/ru/bot-link/:link?",
    component: _c450bd78,
    pathToRegexpOptions: {"strict":true},
    name: "bot-link-link___ru"
  }, {
    path: "/ru/confirm/:token?",
    component: _743042a3,
    pathToRegexpOptions: {"strict":true},
    name: "confirm-token___ru"
  }, {
    path: "/tr/blog/:slug",
    component: _679bbd52,
    pathToRegexpOptions: {"strict":true},
    name: "blog-slug___tr"
  }, {
    path: "/tr/bot-link/:link?",
    component: _c450bd78,
    pathToRegexpOptions: {"strict":true},
    name: "bot-link-link___tr"
  }, {
    path: "/tr/confirm/:token?",
    component: _743042a3,
    pathToRegexpOptions: {"strict":true},
    name: "confirm-token___tr"
  }, {
    path: "/tr/countries/*",
    component: _60ed723a,
    pathToRegexpOptions: {"strict":true},
    name: "countries-all___tr"
  }, {
    path: "/cn/countries/*",
    component: _60ed723a,
    pathToRegexpOptions: {"strict":true},
    name: "countries-all___cn"
  }, {
    path: "/ru/countries/*",
    component: _60ed723a,
    pathToRegexpOptions: {"strict":true},
    name: "countries-all___ru"
  }, {
    path: "/blog/:slug",
    component: _679bbd52,
    pathToRegexpOptions: {"strict":true},
    name: "blog-slug___en"
  }, {
    path: "/bot-link/:link?",
    component: _c450bd78,
    pathToRegexpOptions: {"strict":true},
    name: "bot-link-link___en"
  }, {
    path: "/confirm/:token?",
    component: _743042a3,
    pathToRegexpOptions: {"strict":true},
    name: "confirm-token___en"
  }, {
    path: "/tr/*",
    component: _f1bff256,
    pathToRegexpOptions: {"strict":true},
    name: "all___tr"
  }, {
    path: "/cn/*",
    component: _f1bff256,
    pathToRegexpOptions: {"strict":true},
    name: "all___cn"
  }, {
    path: "/ru/*",
    component: _f1bff256,
    pathToRegexpOptions: {"strict":true},
    name: "all___ru"
  }, {
    path: "/countries/*",
    component: _60ed723a,
    pathToRegexpOptions: {"strict":true},
    name: "countries-all___en"
  }, {
    path: "/*",
    component: _f1bff256,
    pathToRegexpOptions: {"strict":true},
    name: "all___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
