export default {
  header: {
    profile: "Кабинет",
    loginButton: "Вход",
    registrationBtn: "Регистрация",
    feedback: "Обратная связь",
    language: "Язык",
    darkTheme: "Тёмная тема",
    languageToggle: {
      en: "Английский",
      ru: "Русский",
      cn: "Китайский",
      tr: "Турецкий"
    }
  },
  profileNavMenu: {
    goToProfile: "Перейти в ЛК",
    activeNumber: "Полученные номера",
    queryStatistics: "Статистика запросов",
    balanceHistory: "История баланса",
    tariffs: "Тарифы",
    settings: "Настройки",
    balance: "Баланс",
    topupBalance: "Пополнить",
    topupAlert: "",
    logout: "Выход",
    referralProgram: "Реферальная программа",
    saleAgreement: "Продажа лицензий",
  },
  profile: {
    activeNumbers: {
      title: "Полученные номера",
      noActiveNumbers: 'Нет активных номеров',
      serverError: 'Ошибка сервера',
      status: "Статус",
      waitingForSms: "Ожидает СМС",
      time: "Время",
      banned: "В блоке?",
      cancel: "Отмена",
      message: 'Код принят и оплачен. Введите код на стороне сервиса',
      messageMultipleSms: 'В оставшееся время вы можете бесплатно получить от сервиса новые коды',
      blockModalMessage1: 'Данный номер оказался заблокирован на стороне сервиса? <br />Сообщите нам об этом — и мы уберем номер из выдачи',
      blockModalMessage2: 'Денежные средства моментально вернутся на баланс. <br />Попробуйте купить новый номер в той же или другой стране',
      blockBtn: 'Да, номер заблокирован',
      moreSms: "Еще СМС",
      availableIn: "Доступно через: ",
      waitingSms: "Ожидает смс"
    },
    queryStatistics: {
      title: "Статистика запросов",
      noQuery: "Нет запросов",
      buyFor: "Купить за",
    },
    balanceHistory: {
      title: "История баланса",
      outgoing: "Расходы",
      incoming: "Пополнения",
      showMore: "Показать еще",
      noHistory: "Нет истории",
      numberOrder: "Оплата по счету №",
    },
    tariffs: {
      title: 'Тарифы',
    },
    settings: {
      title: "Настройки",
      sound: "Проигрывать звук при получении кода",
      infoAboutServices: "Показывать инфо об активных покупках",
      twoFactorEnabled: "Двухфакторная аутентификация",
      infoAboutBalance: "Нижний порог баланса для уведомления на почту",
      apiKey: "API ключ",
      message: "Сгенерируйте API-ключ для взаимодействия с сайтом через протокол API",
      buttonUpdate: "Обновить API Key",
      updateError: "Ошибка обновления",
      settingsChanged: "Настройки изменены",
      errorChangingSettings: "Ошибка при изменении настроек",
      phoneNumber: "Ваш номер телефона",
      playceholderPhone: "Введите ваш номер",
      refParams: {
        refLink: "Отчет по реферальной программе:",
        refOptions: "Параметры для реферальной программы:",
      },
    },
    myPersonalInformation: {
      title: "Ваши персональные данные",
      yourEmail: "Ваш e-mail",
      yourName: "Ваше имя",
    },
    referralProgram: {
      title: "Реферальная программа",
      referralLinkTitle: "Реферальная ссылка",
      buttonCopy: "Скопировать",
      buttonDownload: "Скачать",
      topUpAmount: "Потрачено вашими рефералами",
      youHaveAlreadyReceived: "Получено реферальных отчислений",
      awaiting: "Остаток на реферальном балансе",
      detailing: "Отчет по реферальной системе",
      derived: 'Из них — выведено',
      formTitle: "Подать заявку",
      formButton: "Запрос на вывод",
      participatePromotion: "Участвовать в акции",
      earnedYourReferrals: "Заработано вашими рефералами"
    }
  },
  profilePay: {
    sectionBalanceReplenishment: {
      title: "Пополнение баланса",
      cardButtonSelect: "Выбрать",
      paymentTitle: "Оплата",
      paymentMessage: "Всегда делайте платеж через интерфейс сайта, нажимая кнопку оплатить, только тогда вы переведете деньги по корректным реквизитам",
      paymentMessageAlipay: "The service only supports users in Chinese Mainland Verified. You can try a method for all AliPay users below.",
      paymentPlaceholder: "Введите сумму *",
      paymentButton: "Оплатить",
      paymentErrorAmount: "Сумма пополнения должна быть больше 0",
      payComment: "Комиссия при пополнении",
      plasticCard: "Банковские карты: Россия — способ №2",
      minAmount: "Минимальный платёж {minPrice} ₽ ",
      minAmountMessage: "Минимальное зачисление — {minPrice} ₽",
      payMessageForPayeer: "При выборе указанного способа, услуги сервиса 7grizzlysms.com будут предоставлены компанией THREETWOTWO LTD. Чтобы продолжить, Вам необходимо согласиться с документами, указанными ниже. ",
      payMessageForPaypalych: "Банковские карты: Россия — способ №4",
      som: 'Банковские карты: Весь мир — способ №2',
      payMessageForNut: "Банковские карты: Весь мир — способ №3",
      stripe: "Банковские карты: Весь мир — способ №1 (Stripe)",
      bankCardsTemplateRussia: "Банковские карты: Россия — способ №3",
      payMessageForTether: "Важно: переводите в точности ту сумму, которая будет указана платёжной системой. В противном случае средства не будет зачислены, а возврат будет стоить $10.",
      alipayTitle: "Для оплаты через AliPay используйте QR-код:",
      alipayText: "...и напишите нам свой регистрационный адрес электронной почты и отправьте скриншот платежа в чат (↘).<br/>Ваш баланс не может быть пополнен автоматически.<br/>Пожалуйста, напишите нам. Минимальный платеж 10 юаней.",
      paymentMessageForConvertor: "Сумма к оплате, {currency}:",
      paymentMessageForConvertorWithCommission: "Сумма к оплате с учетом комиссии, {currency}:",
      willBeCredited: "Будет зачислено, {currency}:",
      messageForConverterDollars: "Мы принимаем доллары и конвертируем их в рубли по курсу: <span> {rate} </span> ₽ за один доллар",
      getNumbersFor: 'Получение номера для {name}',
      usdHelpMessage: 'Информация приведена для справки. Сумму, указанную в интерфейсе платёжного инструмента, просим считать приоритетной. В связи с изменчивостью курса суммы могут незначительно отличаться.'
    },

    aliPay: {
      title: "AliPay and QQpayments",
      messageStep1: "1. Перейдите по ссылке ниже, выберите сумму и оплатите ее:",
      messageStep2: "2. введите код пары, чтобы добавить деньги на баланс SMS Grizzly:",
      send: "Отправить",
      couponNotFound: "Купон не найден",
      placeholderPassword: "Введите код для добавления",
      waitingForRedirectPayment: "Дождитесь перехода на сайт для оплаты",
      paymentCodeNotCorrect: "Код платежа неверен"
    },
    cryptoPayments: {
      label: "Оплата Криптовалютой"
    }
  },
  servicesList: {
    "Выбор_страны": "Выбор страны",
    "Выбор_страны_для": "Выбор страны для {name}",
    title: "Выберите страну",
    titlePopular: "Популярные страны",
    chooseAnother: "Выбрать другую",
    countryInputPlaceholder: "Выберите страну",
    selectedLable: "Выбрать",
    searchInputPlaceholder: "Поиск сервиса",
    buttonWaiting: "Ждите...",
    buttonBuy: "Получить",
    buttonRent: "Аренда",
    getItFor: "Получить за",
    message: 'В настоящее время нет доступных номеров для выбранной страны',
    buttonUpdate: 'Обновить',
    quantity: 'шт',
    quantityFew: 'мало',
    numberTemporarilyUnavailable: "Доступно только по API",
    orderFrom100numbers: "Заказ от 100 номеров через службу поддержки",
    labelCountryHighPercentDelivery: "Страны с высоким % доставки",
    labelSelectOtherCountry: "Выбрать другую страну",
    showMore: "Показать еще",
    from: 'от',
    retail: 'розница',
    authRetail: 'ваша цена',
    wholesale: 'опт',
    telegramMessagePrice: 'Канал c оптовыми ценами:',
    telegramMessageBot: 'Мини-приложение Telegram:',
    telegramMessageNews: 'Новости сервиса:',
    scrollBtn: 'Все сервисы и страны',

    requestNewServiceForm: {
      title: 'Предложить сервис',
      subTitle: 'Не нашли нужный сервис? Расскажите об этом подробнее ниже, и мы исправим ситуацию. <br /> А прямо сейчас предлагаем воспользоваться разделом ',
      anyother: 'Другой',
      dataAboutSite: 'Адрес сайта *',
      smsMessage: 'Текст СМС *',
      countryService: 'Желаемая страна (не обязательно)',
      price: 'Цена номера (не обязательно)',
      btnSend: 'Отправить',
      backToList: 'К списку',
      offerServiceBtn: "Предложить сервис",
    },

    linkToAccount: {
      btnMsg: "готовые аккаунты {accountName}",
      text: "* Работа с готовыми аккаунтами {accountName} требует наличия навыков работы с ПК; набор доступных стран отличается от набора стран для самостоятельной регистрации аккаунтов"
    }
  },
  languagesList: {
    "Выбор_сервиса": "Выбор сервиса",
    "Поиск_по_сервисам": "Поиск по сервисам",
    "Выберите_услугу": "Выберите услугу",
    "Поиск_по_странам": "Поиск по странам",
    "Успешная_покупка_номера": "Номер успешно приобретен. Скоро он появится у вас в \"Полученные номера\"",
    serviceNotFound: 'Сервис не найден',
    repeatMsg: 'Ждать < {min} мин',
    repeatModal: {
      attempt: 'Попытка'
    },
    errorMessages: {
      "NO_NUMBERS": "Номер недоступен. Повторите попытку"
    }
  },
  blog: {
    title: 'Блог',
    buttonDetails: "Подробнее",
    pageNumber: "Страница №",
    readMore: 'Похожие статьи',
    getNumberFor: "Получить номер для"
  },
  footer: {
    title: "Лучший сервис для получения SMS-сообщений онлайн",
    userAgreement: "Пользовательское соглашение",
    referralProgram: "Реферальная программа ",
    returnsPolicy: "Политика возвратов ",
    personalInformation: "Политика обработки персональных данных",
    messageDisabledRussianNumbers: "Абонентские номера российских операторов сотовой связи не предоставляются во временное пользование в силу требований Федерального закона «О связи» от 07.07.2003 N 126-ФЗ",
    footerDisabledRussianNumbers: "Абонентские номера российских операторов сотовой связи не предоставляются во временное пользование в силу требований Федерального закона «О связи» от 07.07.2003 N 126-ФЗ а также на основании пп. ж п. 5 Правил централизованного управления сетью связи общего пользования, утвержденных постановлением Правительства Российской Федерации от 12 февраля 2020 г. N 127 «Об утверждении Правил централизованного управления сетью связи общего пользования»"
  },
  registration: {
    title: 'Регистрация',
    registrationSuccessMessage: " Пользователь успешно зарегистрирован! Ссылка для подтверждения регистрации выслана на почту",
    nameInputPlaceholder: 'Имя *',
    emailInputPlaceholder: 'E-mail *',
    passwordInputPlaceholder: 'Пароль *',
    passwordConfirmInputPlaceholder: 'Подтверждение *',
    buttonRegistration: 'Зарегистрироваться',
    buttonRegistrating: 'Ждите...',
    passwordsMismatchMessage: "Пароли не совпадают",
    backHome: 'Вернуться на главную',
    loginWithSocialNetworks: "Войти с помощью:",
    orRegistrartion: "или зарегистрируйтесь",
    iAgree: "Я согласен",
    privacyPolicy: "с политикой обработки персональных данных и файлов cookie",
    userAgreement: "с публичной офертой",
    alreadyHaveAccount: "У вас уже есть аккаунт?",
    login: "Авторизация",
    messageSuccessConfirmRegistration: "Пользователь успешно зарегистрирован",
  },
  authorization: {
    title: "Авторизация",
    emailInputPlaceholder: 'E-mail',
    passwordInputPlaceholder: 'Пароль',
    tokenInputPlaceholder: 'Код',
    buttonRegistration: 'Авторизация',
    buttonRegistrating: 'Ждите...',
    or: 'или',
    newUser: 'Новый пользователь?',
    registration: 'Зарегистрироваться',
    forgotPassword: "Забыли пароль?",
  },
  resetThePassword: {
    title: "Сбросить пароль",
    buttonReset: 'Сбросить пароль',
    buttonWaiting: 'Ждите...',
    emailInputPlaceholder: 'E-mail',
    message: "Ссылка для сброса пароля отправлена на вашу почту. Пожалуйста, проверьте ваш почтовый ящик и следуйте инструкциям."
  },
  errorsValidationForms: {
    requiredForFill: "Обязательно к заполнению",
    emailErrorMessage: "Некорректный email",
    requaredLengthMessage: "Минимальное количество символов:"
  },
  advantagesBlock: {
    title: "Наши приемущества"
  },
  aboutBlock: {
    showMoreBtn: "Показать еще",
    rollUp: "Свернуть",
  },
  serviceCard: {
    availableNumbers: "Доступные номера",
    price: "Цена",
    buyNow: "Купить",
    emptyCountryListMessage: "Доступных номеров для выбранного сервиса не найдено.<br /> Попробуйте позже"
  },
  feedbackForm: {
    title: "Обратная связь",
    namePlaceholder: "Имя *",
    emailPlaceholder: "Email *",
    messagePlaceholder: "Ваше сообщение *",
    iAgree: "Я согласен",
    privacyPolicy: "с политикой обработки персональных данных и файлов cookie",
    userAgreement: "с публичной офертой",
    buttonSend: 'Отправить',
    buttonRegistrating: 'Ждите...',
    sendError: "Ошибка отправки",
    applicationIsAccepted: "Ваша заявка принята"
  },
  copyToBuffer: {
    success: "Текст скопирован",
    error: "Ошибка копирования",
  },
  breadcrumbs: {
    homePage: "Главная",
    blog: "Блог",
  },
  confirm: {
    passwordReset: "Сбросить пароль",
    newPassword: "Новый пароль",
    confirmPassword: "Подтвердить пароль",
    confirm: "Подтвердить",
    authorization: "Автолризация",
    notMatch: "Пароль не совпалдает",
    changedSuccessfully: "Пароль успешно изменен",
  },
  errorPage: {
    pageNotFound: 'Страница не найдена',
    somethingWentWrong: 'Что-то пошло не так!',
    backHomepage: 'Вернуться на главную'
  },
  support: {
    inputSearch: {
      placeholder: "Введите вопрос",
    }
  },
  successPayment: {
    successMsg: "Оплата прошла успешно",
    waitMsg: "Оплата в процессе завершения.",
    backHomepage: 'На главную'
  },
  searchPage: {
    placeholder: 'Поиск...',
    showMore: "Показать еще",
    nothingFoundMsg: "По вашему запросу ничего не найдено",
    'Статьи': "Статьи",
    'Страницы': "Страницы",
    'Все результаты': "Все результаты",
  },
  common: {
    "downloadFile": "Скачать файл"
  },

  lastArticlesBlock: {
    title: "Блог",
    textBtn: "Наш блог",
  },

  reviewsBlock: {
    title: 'Что пользователи говорят о нас',
    addReviewBtn: 'Оставить отзыв',
    yourName: 'Ваше имя',
    messageTextarea: 'Пожалуйста, отсавьте ваш отзыв',
    sendReview: 'Отправить отзыв',
    success: 'Отзыв успешно отправлен',
    validNameMsg: 'Введите ваше имя',
    validMsg: 'Введите сообщение',
  },

  currencyToggle: {
    tooltipCurrency: 'Цены в USD указаны для справки. Операции с балансом проводятся только в RUB'
  },

  pagination: {
    showing: 'Показано',
    of: 'из',
  },

  additionalCashbackAccrual: {
    message: "<span>+10%</span> кэшбэка на баланс при <span>1-ом</span> пополнении от <span>{{balance}}</span> !",
    buttonCancel: "Понятно",
    buttonPayment: "Пополнить баланс"
  },

  countries: {
    title: 'Доступные страны',
    countries: 'Страны',
    popularServices: 'Популярные сервисы',
    anotherServices: 'Выберите сервис'
  },

  botLinkPage: {
    question: 'Вы действительно хотите подключить ваш аккаунт сайта grizzlysms.com с телеграм аккаунтом <span> {userName}</span> для бота?',
    ok: 'Да',
    cancel: 'Отмена',
    success: 'Телеграм аккаунт успешно подключен',
    alreadyHaveBot: 'У вас уже привязан телеграм бот',
  },

  errors: {
    err1: 'Произошла ошибка. Повторите запрос позднее.'
  },

  partnerPage: {
    becomePartner: "Стать партнёром",
    popUp: {
      title: "Стать партнером",
      yourContactPlaceholder: "Ваш контакт (Telegram, WeChat, WhatsApp, E-mail, другое)",
      countryPlaceholder: "Страны «происхождения» сим-карт",
      equipmentPlaceholder: "Ваше оборудование",
      equipmentPortsPlaceholder: "Количество портов оборудования",
      aboutPlaceholder: "Что ещё нам важно знать о вас?",
      send: "Отправить",
      accept1: "Я согласен",
      accept2: "с политикой конфиденциальности и использования запрашиваемых данных",
      errorForm: "Возникла ошибка, повторите попытку позднее",
      successForm: "Заявка успешно отправлена",
    }
  },

  partnerFormBlock: {
    title: "Стать партнером",
    contactTitle: "Ваш Telegram для связи",
    contactPlaceholder: "Ваш предпочтительный контакт",
    countrySimCard: "Страны «происхождения» номеров",
    countrySimCardPlaceholder: "Через запятую",
    yourEquipment: "Ваше оборудование",
    yourEquipmentPlaceholder: "Расскажите подробно",
    countPort: "Количество портов (от 16)",
    countPortPlaceholder: "Укажите цифрами",
    message: "Что ещё нам важно знать о вас?",
    messagePlaceholder: "Опишите подробно ваш бизнес",
    accountGrizzlySmsEmail: "Аккаунт Grizzly SMS (использованный email-адрес)"
  },
  partnerHeadingBlock: {
    becomePartner: "Стать партнёром",
    yieldCalculator: "Калькулятор доходности"
  },
  partnerCalculatorBlock: {
    country: "Страна",
    priceSim: "Стоимость sim-карты",
    countPort: "Количество портов в модеме",
    netProfit: "Прибыль",
    netProfit3: "Прибыль за 3 месяца",
    netProfit6: "Прибыль за 6 месяцев",
    netProfit12: "Прибыль за 12 месяцев",
  },
  generalSearch: {
    searchByCountry: "Поиск по стране",
    searchByService: "Поиск по сервису",
    title: "Цены на виртуальные номера для приема смс",
    subtitle: "Выберите необходимый вам сервис или страну в таблице ниже",
    tableHeaderService: "Сервис",
    tableHeaderCountry: "Страна",
    tableHeaderCurrency: "Оптовая цена",
    tableHeaderAmount: "Количество",
  },

  profileLoyaltyProgram: {
    cashback: {
      title: "Кэшбэк",
      week_deposit: "Сумма пополнений за текущую неделю",
      projectedCashback: "Прогнозируемый кэшбэк",
      tillNextRank: "До следующего ранга осталось",
      cashbackPercentNextRank: "Кэшбэк в следующем ранге",
      rang: "ранг"
    },
    history: {
      title: "История участия",
      message1: "За пополнения",
      message2: "начислен кэшбэк",
    },
    programConditions: {
      title: "Условия программы лояльности",
      rangLeft: "Ранг: условие получения",
      rangRight: "% кэшбэка",
      rangNotLeft: "Без ранга: пополнения > 0 <1 000 ₽ ",
      rangNotRight: "без Кэшбэка",
      message: "Кэшбэк начисляется пользователям в <span>1-ый день недели (понедельник)</span> по результатам общей суммы пополнений за прошедшую неделю. Процент кэшбэка зависит от ранга, достигнутого пользователям к концу прошлой недели.",
      replenishment: "пополнения",
      cashback: "Кэшбэк"
    },
  },
  license: {
    licenseSuccess: 'Лицензия приобретена и отправлена на регистрационный E-mail',
    buyKey: 'Купить ключ на GrizzlySMS'
  },
  featuresTextBlock: {
    getIndividualNumber: "Получить индивидуальный номер"
  },
  sortComponent: {
    country: 'Топ стран'
  },
  comments: {
    "add_comment": "Добавить комментарий",
    "comments": "Комментарии",
    "collapse": "Свернуть",
    "expand": "Развернуть",
    "send_comment_moderation": "Ваш комментарий отправлен на модерацию.",
    "ony_auth_user": "Доступно только для зарегистрированных"
  },
  saleAgreement: {
    "title": "Продажа лицензий",
    "name": "Наименование",
    "type_po": "Тип ПО",
    "price": "Стоимость",
    "date_purchase": "Дата покупки",
  },

  rentNumbers: {
    typeViews: {
      "number_activation": "Активация",
      "rent_number": "Аренда",
      "readyAccounts": "Аккаунты"
    },
    rentInformation: {
      "rental_period": "Срок аренды",
      "rental_duration": "Длительность аренды",
      "rent": "Арендовать",
      "the_clock": "на часы",
      "for_days": "на дни",
      "specify_rental_duration": "Укажите длительность аренды",
      "maximum_number_hours": "Максимальное кол-во часов",
      "minimal_number_hours": "Минимальное кол-во часов",
      "number_successfully_rented": "Номер успешно арендован",
      "selected_time_not_available": "Номер недоступен",
      "available_minimum_rental_period": "Доступный минимальный срок аренды",
      "available_maximum_rental_period": "Доступный максимальный срок аренды",
    },
    myRentNumbers: {
      "rent_virtual_number": "Аренда виртуального номера",
      "active": "Активные",
      "received": "Полученные",
      "returned": "Отменённые",
      "not_found": "У вас нет арендованных номеров"
    },

    readyNumbers: {
      message: 'ВАЖНО! Если вы впервые покупаете готовые аккаунты, вам необходимо ознакомиться с информацией в разделе <a href="{link}">«Готовые аккаунты»</a> на странице «Поддержка (FAQ)»',
      ok: 'Ознакомлен',
      toFaq: 'Перейти в FAQ',
      cancel: 'Отмена'
    },

    rentNumberCard: {
      "canceled": "Отменён",
      "expires_in": "Истекает через",
      "extension": "Продление",
      "messageTitle": "Сообщения",
      "messageMessage": "Сообщения или звонки пока не приходили. Отправьте смс на номер или позвоните, и информация сразу будет показана здесь.",
      "successCancel": "Аренда номера успешно отменена",
      "successNumberRenewal": "Аренда номера успешно продлена",
      "rentalPeriodExpired": "Истек срок аренды"
    },
    popUpExtendLease: {
      "extend_number_rental": "Продлить аренду номера",
      "going_renew_lease_number": "Вы собираетесь продлить аренду на номере",
      "renewal_history": "История продлений",
      "date": "Дата",
      "price": "Стоимость",
      "term_hours": "Срок в ч.",
      "number_rental_renewal_period": "Срок продления аренды номера",
      "lease_successfully_extended_until": "Аренда успешно продлена до",
      "close": "Закрыть",
      "short_day": "дн.",
      "short_hours": "час.",
      "short_minutes": "мин."
    },
    helpPopUp: {
      message: `<h4>Активация номера</h4>
        <p>Если в течение первых 20 минут вы не получили смс с кодом, вы можете отказаться от номера, деньги вернутся на ваш счет в полном объеме.</p>

        <h4>Аренда номера</h4>
        <p>В течение выбранного периода, у вас всегда будет возможность принять неограниченное количество смс от выбранного сервиса.</p>
        <p>Вы всегда сможете восстановить доступ к зарегистрированному аккаунту!</p>
        <p>Если в течение первых 20 минут вы не получили смс с кодом, вы можете отказаться от номера, деньги вернутся на ваш счет в полном объеме.</p>
        <p>Если вы не получили смс и прошло более 20 минут, отменить аренду будет невозможно.</p>
        <p>Проданный номер в аренду, не будет перепродан под соответствующий сервис по истечению срока аренды.</p>
        <p>Минимальный срок аренды составляет 4 часа, максимальный - 4 недели.</p>
        <p>Есть два варианта аренды номера:</p>
        <p>Полная аренда - в таком случае вам доступен приём всех смс, сервисы которых представлены в нашем каталоге.</p>
        <p>Арендовать номер под конкретный сервис. В этом случае вы получите смс только от того сервиса, который выбрали.</p>

        <h4>Аккаунты</h4>
        <p>Не хотите тратить свое время на ожидание СМС? Вы можете приобрести на нашем сайте уже готовый аккаунт от наших партнёров. В наличии аккаунты Telegram, VK и других сервисов.</p>
        <p>Аккаунты Telegram предоставляются в 2 форматах: TDATA (в аккаунт такого формата можно зайти через ПК и позже перенести на мобильное устройство) и json+session (в этом случае вам понадобится специальное ПО).</p>
        <p>Нюансы работы с готовыми аккаунтами, а также условия возврата описаны в разделе FAQ (кнопка «Поддержка» в меню сайта). Обязательно ознакомьтесь с этой информацией перед покупкой!</p>`
    }
  },

  freeNumbersPopUpRegistration: {
    subtitle: "Не удалось создать аккаунт с помощью бесплатного номера? Зарегистрируйтесь и выберите индивидуальный номер для нужного сервиса — <span>от 1.10 ₽!</span>",
    button: "ПОПРОБУЮ"
  },

  accountShop: {
    mainSectionInfo: {
      my_paid_accounts_title: "Мои оплаченные аккаунты",
      my_paid_accounts_message: "Здесь вы видите список все когда либо приобретённых аккаунтах и необходимых данных для входа",
      my_paid_accounts_button: "Покупка готовых аккаунтов",

      purchasing_ready_accounts_title: "Покупка готовых аккаунтов",
      purchasing_ready_accounts_message: "Мы понимаем, что не всегда есть возможность принять СМС в течение 20 минут. В этом разделе можно купить готовый аккаунт для любого популярного сервиса.",
      purchasing_ready_accounts_button: "Мои оплаченные аккаунты",
    },
    selectedService: {
      title: "Выберите сервис",
      search_placeholder: "Поиск по названию сервиса"
    },
    selectedOffer: {
      title: "Выберите предложение"
    },
    dialogOffer: {
      title: "Купить готовый аккаунт",
      name_of_product: "Наименование товара",
      description_of_product: "Описание товара",
      characteristics: "Характеристики",
      registration_method: "Способ регистрации",
      country: "Страна",
      account_format: "Формат аккаунта",
      availability_cloud_password: "Наличие облачного пароля",
      information_about_purchased_product: "Данные о купленном товаре",
      download_files: "Скачать файлы",
      buy_more: "Купить ещё",
      yes: "Да",
      no: "Нет",
      minimum_order_quantity: "Минимальная количество для заказа"
    },
    myServices: {
      messageAuth: "Необходимо войти в Ваш аккаунт",
      messageEmpty: "Нет купленных аккаунтов"
    }
  },

  partner: {
    breadcrumbs_title: "Партнер",
    documentation: "Документация",
    activations_history: {
      activations_history: "История активаций",
      filter: "Фильтровать",
      export_in: "Экспорт в",
      number: "Номер",
      load_more: 'Показать ещё',
      total_rows: 'Итого: {{ count }} записей',
      select_status: 'Выбрать статус',
      date_activation: 'Дата активации',
      date_from: 'Дата от',
      date_to: 'Дата до',
      ready: 'Выполнен',
      canceled: 'Отменен',
      activation: "Активация (есть смс)"
    },
    sim_card: {
      sim_card: "Сим-карты",
      port: 'Порт',
      info: 'Инфо',
      earned: 'Заработано',
      countServices: 'Количество сервисов'
    },
    price_regulator: {
      price_regulator: "Регулятор цен",
      select_country_started: "Выберите страну чтобы начать",
      services: "Сервисы",
      search_service: "Поиск по сервису",
      all_services: "Все сервисы",
      all_countries: "Все страны",
      popular_services: "Популярные",
      market_price: "Установить рыночную цену",
      competitors_prices: "Показать цены конкурентов",
      pice_successfully_changed: "Цена успешно изменена",
    },
    withdrawal_founds: {
      withdrawal_founds: 'Вывод средств',
      balance: 'Ваш баланс',
      order_payments: 'Заказать выплату',
      currency_type: 'Тип валюты',
      withdrawal_method: 'Способ вывода',
      order_status: 'Статус заявки',
      select_method: 'Выбрать способ',
      select_status: 'Выбрать статус',
      requisites: 'Реквизиты',
      sum: 'Сумма',
      currency: 'Валюта',
      date: 'Дата',
      status: 'Статус',
      nothing_found: 'Ничего не найдено',
      amount_in_currency: 'Сумма в валюте',
      wallet_card_number: 'Номер кошелька / карты',
      comment: 'Комментарий',
      not_necessary_placeholder: 'Необязательно',
      all_btn: 'Всё',
      error_required: 'Заполните все обязательные поля',
      success_msg: 'Ваша заявка принята',
      warning_message: '<span>ВАЖНО!</span> Убедитесь в правильности введенных платежных реквизитов при создании заявки выше️. Администрация grizzlysms.com не несет ответственности в случае потери денежных средств из-за указания некорректных реквизитов оплаты.'
    },
    dialog_competitors_prices: {
      your_price: "Ваша цена",
      competitors_prices: "Цены конкурентов"
    },

    statistic: {
      statistic: 'Статистика монетизации',
      average_SIM: 'В среднем на SIM-карту',
      top_services: 'Топ сервисов',
      activations_count: 'Количество активации в течение 24 часов',
      last_24_hours: 'За последние 24 часа'
    },
  },

  newPayments: {
    minAmount: 'Минимальный платеж:',
    commission: 'Комиссия:',
  },
  soft: {
    "submit_application": "Оставить заявку",
    "search_by_name": "Поиск по названию",
    "all_services": "Все<br/>сервисы",
    "activations": "Активаций"
  },
  productLicensesBlock: {
    availableAt: "Доступно на",
  },
  documentationApi: {
    rent: "API аренды",
    tables: "Таблицы",
    activations: "API активаций",
    expandContent: "Раскрыть содержание",
    collapseContent: "Свернуть содержание",
    successLinkCopy: "Ссылка успешно скопирована",
    successApiKeyCopy: "API Key успешно скопирован",

    select: "Выберите",
    fillIn: "Заполните",
    result: "Результат",
    execute: "Выполнить",
    urlRequest: "URL запроса",
    resultLoading: "Загружаем результат...",
    testMethodNow: "Протестируйте этот метод сейчас"
  },
  marketplaceRegistrationPage: {
    pageTitle: "Регистрация магазина",
    formInputs: {
      name: {
        label: 'Придумайте название своего магазина. Возможно, то как вы его назовете отразит суть вашего продукта, и поможет покупателю при выборе.',
        placeholder: 'Наименование',
        errorMessage: 'Обязательно к заполнению'
      },
      shopDescription: {
        label: 'Описание магазина',
        placeholder: 'Придумайте описание магазина, подчеркните его уникальность и особенности...',
        errorMessage: 'Обязательно к заполнению'
      },
      email: {
        label: 'Ваш Email',
        placeholder: 'Укажите ваш email адрес дял обратной связи...',
        errorMessageForNull: 'Обязательно к заполнению',
        errorMessageNoValid: 'Некорректный email адрес'
      },
    },
    formSendButton: 'Зарегистрировать магазин',
    rulesMarket: {
      iAgree: 'Я согласен',
      rulesPhrase: 'правила и положения торговой площадки Grizzlysms.com'
    },
    offerAgreement: {
      iAccept: 'Я принимаю',
      offerPhrase: 'договор оферты'
    },
    toastSuccessMessage: 'Ваша заявка отправлена'
  }
}
