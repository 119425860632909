//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  computed: {
    currencies: function () {
      return [
        {
          alt: "stripe",
          image: require("~/assets/img/footer/stripe.svg"),
          width: 28, height: 13.3
        },
        {
          alt: "payeer",
          image: require("~/assets/img/footer/payeer-logo.svg"),
          width: 28, height: 5.3
        },
        {
          alt: "tether",
          image: require("~/assets/img/footer/icons8-tether.svg"),
          width: 28, height: 28
        },
        {
          alt: "visa",
          image: require("~/assets/img/footer/visa.svg"),
          width: 28, height: 14
        },
        {
          alt: "mc",
          image: require("~/assets/img/footer/mc_symbol.svg"),
          width: 28, height: 14
        },
        {
          alt: "mir",
          image: require("~/assets/img/footer/mir.svg"),
          width: 28, height: 14
        },
        {
          alt: "alipay",
          image: require("~/assets/img/footer/4373298_alipay_logo_logos_icon.svg"),
          width: 28, height: 28
        },
        {
          alt: "litecoin",
          image: require("~/assets/img/footer/icons8-litecoin.svg"),
          noPadding: true,
          width: 40, height: 40
        },
        {
          alt: "btc",
          image: require("~/assets/img/footer/btc_cryptocurrencies_icon_188406.svg"),
          noPadding: true,
          width: 40, height: 40
        },
        {
          alt: "trx",
          image: require("~/assets/img/footer/trx_cryptocurrencies_icon_188339.svg"),
          noPadding: true,
          width: 40, height: 40
        },
      ]
    },
    footerMenu: function () {
      return (this.$store.state?.cms?.menus?.default?.items || [])
        .filter((t) => !t.isHidden)
    },
    documentsMenu: function () {
      return (this.$store.state?.cms?.menus?.docs?.items || [])
    },
    timestampBuild: function () {
      if (!process.client) {
        return
      }

      const timeStamp = document.querySelector('meta[name="build-version"]').content || "";
      return new Date(Number.parseFloat(timeStamp)).toLocaleString()
    },
    annotationText: function () {
      const vars = this.$store.state.appState?.vars;
      const locale = this.$i18n?.locale || this.$i18n?.defaultLocale;

      const address = [locale, 'Адрес'].filter((t) => !!t).join('_');
      const organization = [locale, 'Название организации и номер'].filter((t) => !!t).join('_');
      return [vars[address], vars[organization]].filter((t) => !!t).join("<span/>");
    },
    socials: function () {
      const items = {
        tg: {
          ru: "https://t.me/GrizzlySMS_News",
          en: "https://t.me/GrizzlySMS_EN",
          cn: "https://t.me/GrizzlySMS_ZH",
        },
        yt: {
          ru: "https://www.youtube.com/@GrizzlySms",
          en: "https://www.youtube.com/@GrizzlySms",
          cn: "https://www.youtube.com/@GrizzlySms",
        },
        fb: {
          ru: "",
          en: "https://www.facebook.com/GrizzlySMScom",
          cn: "",
        },
        dz: {
          ru: "https://dzen.ru/grizzlysms.com",
          en: "",
          cn: "",
        },
        vk: {
          ru: "https://vk.com/grizzlysms",
          en: "",
          cn: "",
        },
        rd: {
          ru: "",
          en: "https://www.reddit.com/r/GrizzlySMS",
          cn: "",
        }
      };
      const localeCode = this.$i18n?.locale;
      let list = [];
      if (items.fb?.[localeCode]) {
        list.push({
          icon: require('~/assets/img/svg/socials/facebook.svg'),
          link: items.fb?.[localeCode],
          name: 'facebook',
          width: 32, height: 32
        })
      }
      if (items.yt?.[localeCode]) {
        list.push({
          icon: require('~/assets/img/svg/socials/youtube.svg'),
          link: items.yt?.[localeCode],
          name: 'youtube',
          width: 32, height: 32
        })
      }
      if (items.tg?.[localeCode]) {
        list.push({
          icon: require('~/assets/img/svg/socials/telegram.svg'),
          link: items.tg?.[localeCode],
          name: 'telegram',
          width: 32, height: 32
        })
      }
      if (items.vk?.[localeCode]) {
        list.push({
          icon: require('~/assets/img/svg/socials/vk.svg'),
          link: items.vk?.[localeCode],
          name: 'vkontakte'
        })
      }
      if (items.dz?.[localeCode]) {
        list.push({
          icon: require('~/assets/img/svg/socials/dzen.svg'),
          link: items.dz?.[localeCode],
          name: 'dzen',
          width: 32, height: 32
        })
      }
      if (items.rd?.[localeCode]) {
        list.push({
          icon: require('~/assets/img/svg/socials/reddit.svg'),
          link: items.rd?.[localeCode],
          name: 'dzen',
          width: 32, height: 32
        })
      }

      return list
    },
  }
}
